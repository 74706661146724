<template>
  <router-view></router-view>
</template>

<script>
import { uiHelper, locale } from '@/util'
export default {
  name: 'CasinoLayout',
  metaInfo: {
    title: `${locale.getMessage('meta.casino_title')}`,
    titleTemplate: `%s`,
    meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.casino_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.casino_keyword')}` }],
    link: [
      {rel: 'canonical', href: window.location.origin + '/casino/'}
    ]
  },
  methods: {
    openLoginDialog() {
      this.$parent.openLoginDialog()
    }
  }
}
</script>
